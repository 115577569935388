import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Creator } from 'apiFolder/directus-main';
import { H2, Paragraph } from 'components/common/Styles';

import LinkedIn from 'images/ExpertPageLinkedIn.svg';
import OpenBrowser from 'images/OpenBrowser.svg';
import defaultPhoto from 'images/generic-article-image.jpeg';

import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import Footer from '../components/Footer/Footer';
import { Content } from '../components/Content';
import Seo from '../components/seo';
import ImageContainerComponent from 'components/common/image/ImageContainer';

interface Context {
	pageContext: {
		item: Creator;
	};
}

const articleExample: React.FunctionComponent<Context> = ({ pageContext: { item } }) => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const {
		name,
		medicalTitle,
		linkedIn,
		authoredArticles,
		contributedArticles,
		reviewedArticles,
		profile,
		profilePicture,
		website,
	} = item;
	const articles = [...authoredArticles, ...reviewedArticles, ...contributedArticles];

	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	return (
		<>
			<Seo title={name} description='' markupSchema={item.markup_schema} />
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<Content820 isOpen={isMenuOpen}>
						<ContentInner>
							<div>
								<Top>
									{profilePicture && (
										<AuthorImg>
											<ImageContainerComponent
												src={`${profilePicture}?key=medium`}
												alt={name}
												thumb={`${profilePicture}?key=small`}
												isRound
											/>
										</AuthorImg>
									)}
									<SpecialistContent>
										<SpecialistText>{medicalTitle}</SpecialistText>
										<H1>{name}</H1>
										<IconsBox>
											{linkedIn && (
												<a href={linkedIn} target='_blank' rel='noopener noreferrer'>
													<img src={LinkedIn} alt='linkedIn' />
												</a>
											)}
											{website && (
												<a href={website} target='_blank' rel='noopener noreferrer'>
													<img src={OpenBrowser} alt='website' />
												</a>
											)}
										</IconsBox>
									</SpecialistContent>
								</Top>

								<ReactMarkdownWrapper>
									<ReactMarkdown children={profile} remarkPlugins={[remarkGfm]} />
									<AboutOur>
										<H2>About Our Review Process</H2>
										<Paragraph>
											At BookPhysio.com, we are dedicated to ensuring that our content is
											accessible, understandable and actionable so that all our readers can be
											confident in making well-informed decisions about their health.
										</Paragraph>
										<Paragraph>
											Medical accuracy and the integrity of our content is paramount to us, so all
											of the articles featured within{' '}
											<Link to='/patient-resources'>our patient resources centre</Link> are vetted
											through our rigorous content development process - this means they are
											fact-checked and reviewed by medical experts before publishing.
										</Paragraph>
										<Paragraph>
											Learn more about our content review process in our{' '}
											<Link to='/editorial-policy'>Editorial Policy</Link>.
										</Paragraph>
									</AboutOur>
								</ReactMarkdownWrapper>
								{articles.length > 0 && (
									<Articles>
										<ArticlesHeading>Articles by {name}</ArticlesHeading>
										{articles.map((article) => (
											<ArticleItem key={article.id}>
												<Photo>
													<ImageContainerComponent
														src={
															article.image ? `${article.image}?key=medium` : defaultPhoto
														}
														alt={article.imageAltText || article.heading!}
														thumb={
															article.image ? `${article.image}?key=small` : defaultPhoto
														}
													/>
												</Photo>
												<div>
													<ArticleHeading>{article.heading}</ArticleHeading>
													<ArticleContent>{article.metaDescription}</ArticleContent>
													<ArticleLink>
														<Link to={article.slug!}>
															Read about {article.articleLinkText}
														</Link>
													</ArticleLink>
												</div>
											</ArticleItem>
										))}
									</Articles>
								)}
							</div>
						</ContentInner>
					</Content820>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const Top = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const AuthorImg = styled.div`
	flex: 0 0 275px;
	width: 275px;
	height: 275px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
const IconsBox = styled.div`
	text-align: center;
	margin-top: 15px;
	& > a {
		display: inline-block;
		margin: 0 6px;
	}
`;

export const H1 = styled.h1`
	font-family: SharpGrotesk-MediumNo21, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 34px;
	line-height: 43px;
	text-align: center;
	color: #152231;
`;
export const SmallPost = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 50px;
`;
export const SmallPostTitle = styled.span`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
`;
export const SmallPostContent = styled.p`
	margin-top: 30px;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	color: #000000;
`;
export const ContentInner = styled.div`
	display: flex;
	padding-top: 80px;

	& h2 {
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 31px;
		color: #000000;
		margin: 0px 0 20px;
		padding-top: 100px;

		position: relative;
		&:before {
			content: '';
			position: absolute;
			top: 50px;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: #e0e0df;
		}
	}

	@media (max-width: 1052px) {
		flex-direction: column;
		padding-top: 50px;
	}
`;
export const RightSide = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 0 80px 60px;
	@media (max-width: 1052px) {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin: 0 0 80px 0;
	}
	@media (max-width: 668px) {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`;
export const RightSideUpdated = styled.span`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #152231;
`;
export const RightSideUpdatedValue = styled.span`
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	color: #748095;
	margin-left: 16px;
`;
export const RightSideLastUpdated = styled.div`
	width: 300px;
	height: 74px;
	background: #f9f8f9;
	border: 1px solid #e5e5e6;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 25px 30px;
`;
export const Specialists = styled.div`
	margin-top: 20px;
	border: 1px solid #e5e5e6;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 25px;
	width: 300px;
`;

export const MedicallyReviewed = styled.div`
	margin-top: 20px;
	background: #f9f8f9;
	border: 1px solid #e5e5e6;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 25px 30px;
	display: flex;
	align-items: center;
	width: 300px;
	@media (max-width: 1052px) {
		margin-top: 0;
		padding: 26px 30px;
	}
	@media (max-width: 668px) {
		margin-top: 20px;
		padding: 26px 30px;
	}
`;
export const MedicallyReviewedContent = styled.span`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #152231;
	margin-right: 16px;
`;
export const Specialist = styled.div`
	border-bottom: 1px solid #bdc5cd;
	margin-top: 20px;
	padding-bottom: 26px;
	display: flex;
	width: 250px;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		border-bottom: none;
		padding-bottom: 6px;
	}
`;
export const SpecialistContent = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 15px;
`;
export const SpecialistName = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: #5b90d4;
	margin-bottom: 2px;
`;
export const SpecialistText = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: #4544f5;
	text-transform: uppercase;
	margin: 30px 0;
`;
const ReactMarkdownWrapper = styled.div`
	margin: 50px 0 25px;
	& p {
		font-size: 17px;
		line-height: 1.5;
		font-weight: 300;
		color: #333e48;
		margin: 25px 0;
		overflow: hidden;
		text-overflow: ellipsis;

		& img {
			max-width: 750px;
			width: 100%;
			object-fit: cover;
		}
	}

	& ul {
		margin: 10px 0 20px 20px;
	}
	& ol {
		& li {
			margin: 0 0 0 15px;
		}
	}

	& a {
		color: #424bec;
		transition: all 0.3s ease;

		&:hover {
			color: #000000;
			text-decoration: none;
		}
	}

	& td,
	th {
		padding-right: 10px;
		padding-bottom: 10px;
	}

	@media (max-width: 1052px) {
		margin: 50px 0 70px;
	}
`;
const AboutOur = styled.div``;
const Articles = styled.div`
	margin-bottom: 120px;
`;
const ArticlesHeading = styled.h2`
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 31px;
	color: #000000;
	margin-bottom: 50px;
`;
const ArticleItem = styled.div`
	margin-bottom: 40px;
	display: flex;
	@media (max-width: 500px) {
		flex-wrap: wrap;
	}
`;
const Photo = styled.div`
	flex: 0 0 200px;
	width: 200px;
	height: 108px;
	margin-right: 30px;
	box-sizing: content-box;
	position: relative;
	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@media (max-width: 500px) {
		margin: 20px auto 16px;
	}
`;
const ArticleHeading = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	color: #152231;
	margin-bottom: 10px;
	@media (max-width: 500px) {
		margin-top: 16px;
	}
`;
const ArticleContent = styled.p`
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	color: #000000;
	margin: 10px 0;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
`;
const ArticleLink = styled.div`
	& a {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #424bec;
		margin: 0 2px;
		transition: all 0.3s ease;
		cursor: pointer;
		text-decoration: none;
		&:hover {
			color: #7c7c7c;
		}
	}
`;
export const Content820 = styled(Content)`
	max-width: 850px;
`;

export default articleExample;
